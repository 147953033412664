import { gql, TypedDocumentNode, useMutation } from '@apollo/client';
import { useNotifications } from '@petmate/ui';
import { GetProductsQuery } from 'src/products/forms/product-form/api/queries/get-products.query';

export interface DeleteProductVariables {
  uuid: string;
}

export interface DeleteProductData {
  deleteProduct: {
    uuid: string;
    name: string;
    slug: string;
    description: string;
    category: {
      uuid: string;
    };
  };
}

export const DeleteProductMutation: TypedDocumentNode<
  DeleteProductData,
  DeleteProductVariables
> = gql`
  mutation DeleteProductMutation($uuid: String!) {
    deleteProduct(uuid: $uuid) {
      uuid
    }
  }
`;

export const useDeleteProductMutation = () => {
  const { success, error } = useNotifications();
  return useMutation<DeleteProductData, DeleteProductVariables>(
    DeleteProductMutation,
    {
      refetchQueries: [GetProductsQuery],
      onCompleted: () => {
        success({
          title: 'Success',
          message: 'The product has been deleted successfully',
        });
      },
      onError: () => {
        error({
          title: 'Error',
          message: 'An error occurred while deleting the product',
        });
      },
    }
  );
};
