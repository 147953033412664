import { Button } from '@petmate/ui';
import { useForm } from 'react-hook-form';
import { yupResolver } from 'src/shared/validation/resolver/yup';
import { useGetPossibleProductCategoriesQuery } from './api/queries/get-possible-product-categories.query';
import { GeneralInfo } from './components/general-info';
import { ProductVariants } from './components/product-variants/product-variants';
import { Content, Footer, Form } from './product-form.styles';
import { ProductFormValues } from './product-form.types';
import { validationSchema } from './product-form.validation';

export const initialValues: ProductFormValues = {
  name: '',
  slug: '',
  description: '',
  category: {
    uuid: '',
  },
  variants: [],
};

export interface ProductFormProps {
  submitLabel: string;
  onSubmit: (values: ProductFormValues) => void;
  values?: ProductFormValues;
}

export const ProductForm = ({
  onSubmit,
  submitLabel,
  values,
}: ProductFormProps) => {
  const { data } = useGetPossibleProductCategoriesQuery({
    variables: { relations: ['attributes.values'] },
  });
  const { control, watch, formState, handleSubmit } =
    useForm<ProductFormValues>({
      defaultValues: values || initialValues,
      resolver: yupResolver(validationSchema),
    });

  const categoryUuid = watch('category');
  const category = data?.categories.find(
    (category) => category.uuid === categoryUuid?.uuid
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Content>
        <GeneralInfo control={control} />
        {category && (
          <ProductVariants control={control} attributes={category.attributes} />
        )}
      </Content>
      <Footer>
        <Button
          data-testid="product-form-save-draft"
          variant="secondary"
          label="Save as draft"
        />
        <Button
          data-testid="product-form-submit"
          disabled={!formState.isValid && formState.isDirty}
          label={submitLabel}
        />
      </Footer>
    </Form>
  );
};
