import { useLocation, useNavigate } from 'react-router-dom';
import { Role } from 'src/auth/hooks/use-can';
import { useProtectedRoute } from 'src/auth/hooks/use-protected-route';
import { useCreateCategoryMutation } from 'src/categories/gql/mutations/create-category.mutation';
import { Page } from 'src/shared/components/page';
import { PageHeader } from 'src/shared/components/page-header';
import { paths } from 'src/shared/constants/paths';
import { CategoryForm } from '../../forms/category-form';

export const CreateCategory = () => {
  const navigate = useNavigate();
  useProtectedRoute([Role.Admin, Role.Manager]);
  const location = useLocation();

  const [mutate, { loading }] = useCreateCategoryMutation({
    async onCompleted() {
      navigate(location.state?.redirect || paths.categories.list());
    },
  });

  return (
    <Page width={40}>
      <PageHeader title="Create Category" canGoBack />
      <CategoryForm
        pending={loading}
        onSubmit={(data) =>
          mutate({
            variables: { data: { ...data, order: Number(data.order) } },
          })
        }
        submitLabel="Create category"
      />
    </Page>
  );
};
