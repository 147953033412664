import { useQuery } from '@apollo/client';
import { Button } from '@petmate/ui';
import { Select, TextField } from '@petmate/ui/rhf';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { GetRoles } from 'src/users/gql/queries/get-roles.query.gql';
import * as yup from 'yup';
import {
  Fields,
  Footer,
  Header,
  Heading,
  Row,
  Section,
} from './user-form.styles';

interface Role {
  uuid: string;
  name: string;
  displayName: string;
  description: string;
}

export interface UserFormProps {
  onSubmit: (values: UserFormValues) => void;
  errors?: Record<string, string>;
  submitLabel: string;
  values?: UserFormValues;
}

export interface UserFormValues {
  email: string;
  password: string;
  password_confirmation: string;
  role: {
    uuid: string;
  };
  profile: {
    firstName: string;
    lastName: string;
    phone: string;
  };
}

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
  password_confirmation: yup.string().required(),
  role: yup
    .object()
    .shape({
      uuid: yup.string().required(),
    })
    .required(),
  profile: yup.object().shape({
    firstName: yup.string(),
    lastName: yup.string(),
    phone: yup.string(),
  }),
});

const initialValues: UserFormValues = {
  email: '',
  password: '',
  password_confirmation: '',
  role: {
    uuid: '',
  },
  profile: {
    firstName: '',
    lastName: '',
    phone: '',
  },
};

export const UserForm = ({
  onSubmit,
  errors,
  submitLabel,
  values,
}: UserFormProps) => {
  const { data, loading } = useQuery<{ roles: Role[] }>(GetRoles);
  const { handleSubmit, control, setError } = useForm<UserFormValues>({
    errors,
    values: values || initialValues,
    defaultValues: initialValues
  });

  useEffect(() => {
    for (const key in errors) {
      setError(key as keyof UserFormValues, {
        type: 'manual',
        message: errors[key],
      });
    }
  }, [errors, setError]);

  const onSubmitHandle = (values: UserFormValues) => {
    onSubmit(values);
  };

  const resolvedData = loading
    ? []
    : data!.roles.map((role) => ({
        label: role.name,
        value: role.uuid,
      }));

  return (
    <form onSubmit={handleSubmit(onSubmitHandle)}>
      <Section>
        <Header>
          <Heading>Informacje o koncie</Heading>
        </Header>
        <Fields>
          <TextField
            control={control}
            label="Adres email"
            name="email"
            data-testid={TEST_IDS.emailField}
          />
          <TextField
            control={control}
            label="Hasło"
            name="password"
            data-testid={TEST_IDS.passwordField}
          />
          <TextField
            control={control}
            label="Powtórz hasło"
            name="password_confirmation"
            data-testid={TEST_IDS.passwordConfirmationField}
          />
        </Fields>
      </Section>
      <Section>
        <Header>
          <Heading>Rola użytkownika</Heading>
        </Header>
        <Fields>
          <Select
            control={control}
            label="Rola"
            name="role.uuid"
            options={resolvedData}
            data-testid={TEST_IDS.roleSelect}
          />
        </Fields>
      </Section>

      <Section>
        <Header>
          <Heading>Informacje o użytkowniku</Heading>
        </Header>
        <Fields>
          <Row>
            <TextField
              control={control}
              label="Imię"
              name="profile.firstName"
              data-testid={TEST_IDS.firstNameField}
            />
            <TextField
              control={control}
              label="Nazwisko"
              name="profile.lastName"
              data-testid={TEST_IDS.lastNameField}
            />
          </Row>
          <TextField
            control={control}
            label="Numer telefonu"
            name="profile.phone"
            data-testid={TEST_IDS.phoneField}
          />
        </Fields>
      </Section>
      <Footer>
        <Button
          type="submit"
          label={submitLabel}
          size="medium"
          data-testid={TEST_IDS.submitButton}
        />
      </Footer>
    </form>
  );
};

const TEST_IDS = {
  emailField: 'email-field',
  passwordField: 'password-field',
  passwordConfirmationField: 'password-confirmation-field',
  roleSelect: 'role-select',
  firstNameField: 'first-name-field',
  lastNameField: 'last-name-field',
  phoneField: 'phone-field',
  submitButton: 'submit-button',
};
