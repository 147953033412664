import { Button } from '@petmate/ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Select } from 'src/shared/components/select';
import { TagsInput } from 'src/shared/components/tags-input';
import { TextField } from 'src/shared/components/textfield';
import { yupResolver } from 'src/shared/validation/resolver/yup';
import { Wrapper } from './attribute-form.styles';
import { AttributeFormValues } from './attribute-form.types';
import { validation } from './attribute-form.validation';

export interface AttributeFormProps {
  values?: AttributeFormValues;
  onSubmit: (data: AttributeFormValues) => void;
  submitLabel: string;
  errors?: Record<string, string>;
}

const initialValues: AttributeFormValues = {
  name: '',
  displayName: '',
  description: '',
  unit: '',
  valueType: 'string',
  values: [],
};

export const AttributeForm = ({
  values,
  onSubmit,
  submitLabel,
  errors,
}: AttributeFormProps) => {
  const { handleSubmit, watch, control, setError } =
    useForm<AttributeFormValues>({
      values: values || initialValues,
      resolver: yupResolver(validation),
    });

  const type$ = watch('valueType');

  useEffect(() => {
    for (const key in errors) {
      setError(key as keyof AttributeFormValues, {
        type: 'manual',
        message: errors[key],
      });
    }
  }, [errors, setError]);

  return (
    <form data-testid={TEST_IDS.form} onSubmit={handleSubmit(onSubmit)}>
      <Wrapper>
        <TextField<AttributeFormValues>
          name="name"
          data-testid={TEST_IDS.nameInput}
          control={control}
          label="Name"
        />
        <TextField<AttributeFormValues>
          name="displayName"
          data-testid={TEST_IDS.displayNameInput}
          control={control}
          label="Display name"
        />

        <Select<AttributeFormValues>
          label="Type"
          name="valueType"
          data-testid={TEST_IDS.typeInput}
          options={[
            { label: 'String', value: 'string' },
            { label: 'Number', value: 'number' },
            { label: 'Boolean', value: 'boolean' },
            { label: 'Dictionary', value: 'dictionary' },
          ]}
          control={control}
        />

        {['string', 'number'].includes(type$) && (
          <TextField<AttributeFormValues>
            name="unit"
            data-testid={TEST_IDS.unitInput}
            control={control}
            label="Unit"
          />
        )}

        {type$ === 'dictionary' && (
          <TagsInput
            data-testid={TEST_IDS.valuesInput}
            control={control}
            name="values"
          />
        )}
        <div>
          <Button
            size="medium"
            label={submitLabel}
            data-testid={TEST_IDS.submitButton}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </Wrapper>
    </form>
  );
};

const TEST_IDS = {
  form: 'attribute-form',
  nameInput: 'attribute-form-name-input',
  displayNameInput: 'attribute-form-display-name-input',
  typeInput: 'attribute-form-type-input',
  unitInput: 'attribute-form-unit-input',
  valuesInput: 'attribute-form-values-input',
  submitButton: 'attribute-form-submit-button',
};
