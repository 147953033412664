import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  ButtonGroup,
  ConfirmModal,
  DataTable,
  DataTableColumn,
  Icon,
  IconButton,
  useNotifications,
} from '@petmate/ui';
import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { PageHeader } from 'src/shared/components/page-header';
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
} from 'src/shared/constants/pagination';
import { User } from 'src/shared/types/user.type';
import { DeleteUserMutation } from '../../gql/mutations/delete-user.mutation.gql';
import { GetUsersQuery } from '../../gql/queries/get-users.query.gql';
import { Wrapper } from './list-users.styles';

const columns: DataTableColumn<User>[] = [
  {
    label: 'Email',
    accessor: 'email',
    sortable: true,
    width: '40%',
  },
  {
    label: 'Name',
    accessor: 'profile',
    render: ({ profile }) => `${profile.firstName} ${profile.lastName}`,
    width: '30%',
  },
  {
    label: 'Role',
    accessor: 'role',
    render: ({ role }) => role.displayName,
    width: '30%',
  },
];

export const Actions = ({ row }) => {
  const client = useApolloClient();
  const [modalOpen, setModalOpen] = useState(false);
  const { error, success } = useNotifications();
  const [deleteUser] = useMutation(DeleteUserMutation, {
    onCompleted() {
      setModalOpen(false);
      client.refetchQueries({ include: [GetUsersQuery] });
      success({
        title: 'Success',
        message: 'User deleted successfully!',
      });
    },
    onError() {
      error({
        title: 'Error',
        message: 'An error occurred while deleting the user',
      });
    },
  });

  const handleModalSubmit = async () => {
    await deleteUser({
      variables: {
        uuid: row.uuid,
      },
    });
  };

  return (
    <ButtonGroup>
      <IconButton
        data-testid={TEST_IDS.viewUserButton}
        as={Link}
        to={`/users/${row.uuid}`}
        iconSize={16}
        variant="info"
        name="eye"
        data-testid="show-button"
      />
      <IconButton
        data-testid={TEST_IDS.editUserButton}
        as={Link}
        to={`/users/${row.uuid}/edit`}
        iconSize={16}
        variant="warning"
        name="edit"
        data-testid="edit-button"
      />
      <IconButton
        data-testid={TEST_IDS.deleteUserButton}
        iconSize={16}
        variant="error"
        name="trash"
        onClick={() => setModalOpen(true)}
        data-testid="delete-button"
      />
      <ConfirmModal
        data-testid={TEST_IDS.deleteUserModal}
        cancelText="Nie, zostaw"
        confirmText="Tak, usuń"
        title={`Czy na pewno chcesz usunąć użytkownika: ${row.profile.firstName} ${row.profile.lastName}?`}
        onCancel={() => setModalOpen(false)}
        onConfirm={handleModalSubmit}
        open={modalOpen}
      />
    </ButtonGroup>
  );
};

export interface ListUsersSort {
  column: any;
  direction: 'asc' | 'desc';
}

export const ListUsers = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get('page')) || DEFAULT_PAGE;
  const pageSize = Number(searchParams.get('pageSize')) || DEFAULT_PAGE_SIZE;
  const [sort, setSort] = useState<ListUsersSort>({
    column: 'createdAt',
    direction: 'desc',
  });
  const { data, loading, error } = useQuery(GetUsersQuery, {
    variables: {
      pagination: { page, pageSize },
      sort,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleSortChange = (sort: ListUsersSort) => {
    setSort(sort);
    const params: Record<string, string> = {};

    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    setSearchParams({
      ...params,
      sort: sort.column,
      sortDirection: sort.direction,
    });
  };

  const { pagination, payload } = data.users;

  return (
    <Wrapper>
      <PageHeader
        title="Users"
        canGoBack
        actions={[
          <Button
            as={Link}
            data-testid={TEST_IDS.addUserButton}
            label="Add user"
            to="/users/create"
            leftComponent={<Icon name="plus" size={20} />}
            size="medium"
          />,
        ]}
      />
      <DataTable
        actions={(row) => <Actions row={row} />}
        pagination={pagination}
        onPageChange={setSearchParams}
        onPageSizeChange={setSearchParams}
        onSortChange={handleSortChange}
        sort={sort}
        columns={columns}
        data={payload}
        testId={TEST_IDS.usersTable}
      />
    </Wrapper>
  );
};

const TEST_IDS = {
  addUserButton: 'add-user-button',
  usersTable: 'users-table',
  viewUserButton: 'show-button',
  editUserButton: 'edit-button',
  deleteUserButton: 'delete-button',
  deleteUserModal: 'delete-user-modal',
};
