import {
  Button,
  ButtonGroup,
  ConfirmModal,
  DataTable,
  DataTableColumn,
  Icon,
  IconButton,
} from '@petmate/ui';
import { useState } from 'react';
import { Control, useController, useFieldArray } from 'react-hook-form';
import { Attribute } from 'src/shared/types/attribute.type';
import {
  ProductFormValues,
  ProductVariantFormValues,
} from '../../product-form.types';
import { ProductVariantModal } from '../product-variant-modal';
import { PhotoThumbnail, Wrapper } from './product-variants.styles';

const columns: DataTableColumn<ProductVariantFormValues>[] = [
  {
    accessor: (row) => <PhotoThumbnail src={row.photos[0].fileUrl} />,
    label: '',
    width: '10%',
  },
  {
    accessor: 'name',
    label: 'Nazwa wariantu',
    width: '20%',
  },
  {
    accessor: 'ean',
    label: 'EAN',
    width: '15%',
  },
  {
    accessor: 'sku',
    label: 'SKU',
    width: '15%',
  },
  {
    accessor: 'price',
    label: 'Cena',
    width: '15%',
    align: 'center',
  },
  {
    accessor: 'stock',
    label: 'Stan magazynowy',
    width: '25%',
    align: 'center',
  },
];

const ProductVariantActions = ({
  row,
  onEdit,
  onDelete,
}: {
  row: ProductVariantFormValues;
  onEdit: (row: ProductVariantFormValues) => void;
  onDelete: () => void;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <ButtonGroup>
        <IconButton
          name="edit"
          variant="warning"
          iconSize={16}
          onClick={() => onEdit(row)}
        />
        <IconButton
          name="trash"
          variant="error"
          iconSize={16}
          onClick={() => setModalOpen(true)}
        />
      </ButtonGroup>
      <ConfirmModal
        cancelText="Nie, zostaw"
        confirmText="Tak, usuń"
        title={`Czy na pewno chcesz usunąć wariant: ${row.name}?`}
        onConfirm={onDelete}
        onCancel={() => setModalOpen(false)}
        open={modalOpen}
      />
    </>
  );
};

export interface ProductVariantsProps {
  attributes: Attribute[];
  control: Control<ProductFormValues>;
}

export const ProductVariants = ({
  attributes,
  control,
}: ProductVariantsProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [editedVariant, setEditedVariant] = useState<
    ProductVariantFormValues | undefined
  >(undefined);
  const [editedVariantIndex, setEditedVariantIndex] = useState<number | null>(
    null
  );
  const { field } = useController({ control, name: 'variants' });
  const { append, remove, update } = useFieldArray({
    control,
    name: 'variants',
  });

  const isModalOpen = modalOpen || !!editedVariant;

  const onModalSubmit = (data: ProductVariantFormValues) => {
    if (editedVariantIndex !== null) {
      update(editedVariantIndex, data);
    } else {
      append(data);
    }
    setEditedVariant(undefined);
    setEditedVariantIndex(null);
    setModalOpen(false);
  };

  return (
    <Wrapper>
      <DataTable
        checkAllVisible={true}
        columns={columns}
        data={field.value}
        actions={(row, index) => (
          <ProductVariantActions
            row={row}
            onEdit={() => {
              setEditedVariant(row);
              setEditedVariantIndex(index);
            }}
            onDelete={() => {
              remove(index);
            }}
          />
        )}
      />
      <div>
        <Button
          type="button"
          leftComponent={<Icon name="plus" size={16} />}
          size="medium"
          variant="secondary"
          label="Dodaj wariant"
          onClick={() => setModalOpen(true)}
          data-testid="add-variant-button"
        />
      </div>
      {isModalOpen && (
        <ProductVariantModal
          open={isModalOpen}
          onClose={() => {
            setModalOpen(false);
            setEditedVariant(undefined);
          }}
          onSubmit={onModalSubmit}
          attributes={attributes}
          values={editedVariant}
        />
      )}
    </Wrapper>
  );
};
