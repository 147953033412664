import { IconButton } from '@petmate/ui';
import styled from 'styled-components';

export const Wrapper = styled.div<{ $drawerExpanded: boolean }>`
  display: grid;
  grid-template-areas:
    'navigation topbar'
    'navigation content';
  grid-template-columns: 60px 1fr;
  position: relative;
  transition: all 0.3s ease-in-out;

  ${({ $drawerExpanded }) =>
    $drawerExpanded &&
    `
    grid-template-columns: 320px 1fr;
  `}
`;

export const LogoWrapper = styled.div`
  min-width: 126px;
`

export const TopBar = styled.div<{ $drawerExpanded: boolean }>`
  grid-area: topbar;
  background-color: var(--white);
  border-bottom: 1px solid var(--gray-300);
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  right: 0;
  left: 60px;
  height: 60px;
  z-index: 10;

  ${({ $drawerExpanded }) =>
    $drawerExpanded &&
    `
    left: 320px;
  `}
`;

export const Burger = styled(IconButton)`
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
`

export const Drawer = styled.div<{ $drawerExpanded: boolean }>`
  grid-area: navigation;
  background-color: var(--green-600);
  display: flex;
  flex-direction: column;
  color: var(--green-200);
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 20;
  width: 60px;

  ${({ $drawerExpanded }) =>
    $drawerExpanded &&
    `
    width: 320px;
  `}
`;

export const Content = styled.div<{ $drawerExpanded: boolean }>`
  grid-area: content;
  padding: 20px;
  margin-top: 60px;
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  min-height: 60px;
  border-bottom: 1px solid var(--green-500);
  padding: 0 18px 0 16px;
  column-gap: 20px;
`;

export const BottomNav = styled.div`
  margin-top: auto;
`;