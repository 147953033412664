import { Button, DataTable, DataTableColumn, Icon } from '@petmate/ui';
import { Link } from 'react-router-dom';
import { Role, useCan } from 'src/auth/hooks/use-can';
import { useGetProductsQuery } from 'src/products/forms/product-form/api/queries/get-products.query';
import { Page } from 'src/shared/components/page';
import { PageHeader } from 'src/shared/components/page-header';
import { paths } from 'src/shared/constants/paths';
import { Product } from 'src/shared/types/product.type';
import { ListProductsActions } from './list-products.actions';

const columns: DataTableColumn<Product>[] = [
  {
    label: 'Name',
    accessor: 'name',
    width: '50%',
  },
  {
    label: 'Category',
    accessor: 'name',
    render: (product) => product.category.name,
    align: 'center',
    width: '30%',
  },
  {
    label: 'Variants',
    accessor: 'variants',
    render: (product) => product.variants.length,
    align: 'center',
    width: '20%',
  },
];

export const ListProducts = () => {
  const { can } = useCan();
  const { data } = useGetProductsQuery({ fetchPolicy: 'network-only' });
  return (
    <Page>
      <PageHeader
        title="Products"
        actions={[
          can([Role.Admin, Role.Manager]) ? (
            <Button
              as={Link}
              to={paths.products.create()}
              label="Add product"
              leftComponent={<Icon name="plus" size={20} />}
              size="medium"
            />
          ) : null,
        ]}
      />
      <DataTable
        columns={columns}
        data={data.products.payload}
        actions={(row) => <ListProductsActions row={row} />}
      />
    </Page>
  );
};
