import { Control } from 'react-hook-form';
import { CategoryPicker } from 'src/categories/components/category-picker';
import { TextArea } from 'src/shared/components/text-area';
import { TextField } from 'src/shared/components/textfield';
import { Header, Heading } from '../../product-form.styles';
import { ProductFormValues } from '../../product-form.types';
import { Wrapper } from './general-info.styles';

interface GeneralInfoProps {
  control: Control<ProductFormValues>;
}

export const GeneralInfo = ({ control }: GeneralInfoProps) => {
  return (
    <Wrapper>
      <Header>
        <Heading>Informacje o produkcie</Heading>
      </Header>
      <TextField
        data-testid="product-name"
        control={control}
        label="Name"
        name="name"
      />
      <TextField
        data-testid="product-slug"
        control={control}
        label="Slug"
        name="slug"
      />
      <CategoryPicker
        data-testid="product-category"
        control={control}
        name="category.uuid"
      />
      <TextArea
        data-testid="product-description"
        rows={10}
        label="Description"
        name="description"
        control={control}
      />
    </Wrapper>
  );
};
