import axios from 'axios';
import { useMutation } from 'react-query';

export const uploadProductPhotoRequest = async (
  photo: File,
  progressCallback?: (progress: number) => void
) => {
  const url = `${import.meta.env.VITE_API_URL.replace('/graphql', '')}/files/upload`;

  const formData = new FormData();
  formData.append('file', photo);
  formData.append('type', 'product');

  const response = await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) => {
      const totalProgress = progressEvent.progress || 0;
      progressCallback?.(totalProgress);
    },
  });

  return response.data;
};

export interface UploadProductPhotoOptions {
  onUploadProgress: (progress: number) => void;
}

export const useUploadProductPhotoMutation = (
  options?: UploadProductPhotoOptions
) => {
  return useMutation({
    mutationKey: ['uploadProductPhoto'],
    mutationFn: (photo: File) =>
      uploadProductPhotoRequest(photo, options?.onUploadProgress),
  });
};
