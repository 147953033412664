import { Avatar, Breadcrumbs } from '@petmate/ui';
import { PropsWithChildren, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import 'src/shared/styles/index.scss';
import Logo from '../../../shared/assets/images/logo.svg?react';
import { useLayoutContext } from '../../context/layout.context';
import { Navigation } from '../navigation';
import { NavigationItem } from '../navigation-item';
import {
  BottomNav,
  Burger,
  Content,
  Drawer,
  LogoWrapper,
  Toolbar,
  TopBar,
  Wrapper,
} from './layout.styles';

import { Outlet } from 'react-router-dom';
import { useAuthContext } from 'src/auth/context/auth.context';
import { Role, useCan } from 'src/auth/hooks/use-can';
import { useProtectedRoute } from 'src/auth/hooks/use-protected-route';
import { paths } from 'src/shared/constants/paths';

export const Layout = ({ children }: PropsWithChildren) => {
  useProtectedRoute();
  const { logout } = useAuthContext();
  const { can } = useCan();
  const { drawerExpanded, toggleDrawer } = useLayoutContext();
  return (
    <Wrapper $drawerExpanded={drawerExpanded}>
      <Drawer $drawerExpanded={drawerExpanded}>
        <Toolbar>
          <Burger onClick={toggleDrawer} name="menu" />
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        </Toolbar>
        <Navigation
          items={[
            {
              id: 'dashboard',
              icon: 'dashboard',
              label: 'Dashboard',
              url: paths.dashboard(),
              visible: can([Role.Admin, Role.Employee, Role.Manager]),
            },
            {
              id: 'attributes',
              icon: 'attributes',
              label: 'Atrybuty',
              url: paths.attributes.list(),
              visible: can([Role.Admin, Role.Employee, Role.Manager]),
            },
            {
              id: 'categories',
              icon: 'categories',
              label: 'Kategorie',
              url: paths.categories.list(),
              visible: can([Role.Admin, Role.Manager]),
            },
            {
              id: 'products',
              icon: 'products',
              label: 'Produkty',
              url: paths.products.list(),
              visible: can([Role.Admin, Role.Employee, Role.Manager]),
            },
            {
              id: 'orders',
              icon: 'orders',
              label: 'Zamówienia',
              url: paths.orders.list(),
              visible: can([Role.Admin, Role.Employee, Role.Manager]),
            },
            {
              id: 'users',
              icon: 'user',
              label: 'Użytkownicy',
              url: paths.users.list(),
              visible: can([Role.Admin]),
            },
          ]}
        />
        <BottomNav>
          <NavigationItem
            id="settings"
            label="Settings"
            url={paths.settings()}
            icon="settings"
          />
          <NavigationItem
            id="logout"
            onClick={logout}
            label="Logout"
            url={paths.logout()}
            icon="logout"
          />
        </BottomNav>
      </Drawer>
      <TopBar $drawerExpanded={drawerExpanded}>
        <Breadcrumbs items={['Dashboard', 'Categories']} />
        <Avatar src="https://picsum.photos/100/100" alt="User User" />
      </TopBar>
      <Content id="scrollable-content">
        <ErrorBoundary fallback={<div>Something went wrong</div>}>
          <Suspense>
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </Content>
    </Wrapper>
  );
};
